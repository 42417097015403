import { Status } from '../../../../interfaces';
import { DataRequestTypeGuidEnum } from '../../../../utils/enums/DataRequestsEnums';
import { SubStatusEnums } from '../../../../utils/enums/SubStatusEnums';
import * as CommonColumnsEnums from './CommonColumns.enums';

const ALIGN_CENTER = 'center';

export const commonColumnProps = {
  [CommonColumnsEnums.EColumnId.ACTIONS]: {
    align: ALIGN_CENTER,
    id: CommonColumnsEnums.EColumnId.ACTIONS
  },
  [CommonColumnsEnums.EColumnId.ASSIGNED_TO]: {
    id: CommonColumnsEnums.EColumnId.ASSIGNED_TO,
    label: CommonColumnsEnums.EColumnId.ASSIGNED_TO,
    sortable: true
  },
  [CommonColumnsEnums.EColumnId.COMMENT]: {
    align: ALIGN_CENTER,
    id: CommonColumnsEnums.EColumnId.COMMENT
  },
  [CommonColumnsEnums.EColumnId.DATA_TYPE]: {
    id: CommonColumnsEnums.EColumnId.DATA_TYPE,
    label: CommonColumnsEnums.EColumnId.DATA_TYPE
  },
  [CommonColumnsEnums.EColumnId.DUE_DATE]: {
    id: CommonColumnsEnums.EColumnId.DUE_DATE,
    label: CommonColumnsEnums.EColumnId.DUE_DATE,
    sortable: true
  },
  [CommonColumnsEnums.EColumnId.STATUS]: {
    id: CommonColumnsEnums.EColumnId.STATUS,
    label: CommonColumnsEnums.EColumnId.STATUS
  },
  [CommonColumnsEnums.EColumnId.TITLE]: {
    id: CommonColumnsEnums.EColumnId.TITLE,
    label: CommonColumnsEnums.EColumnId.TITLE,
    sortable: true
  }
};

export const getFilterActiveStatus = (filters: string[]) => filters.length > 0;

export const dataTypeColumnFilterProps = {
  filterActive: getFilterActiveStatus,
  filterOptions: [
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.AUDIT_WORKPAPERS,
      value: DataRequestTypeGuidEnum.AUDIT_WORKPAPERS
    },
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.DOCUMENT_IMAGES,
      value: DataRequestTypeGuidEnum.DOCUMENT_IMAGES
    },
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.ERP_DATA,
      value: DataRequestTypeGuidEnum.ERP_DATA
    },
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.ORGANIZATIONAL_CHART,
      value: DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
    },
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.TAX_RETURNS,
      value: DataRequestTypeGuidEnum.TAX_RETURNS
    },
    {
      label: CommonColumnsEnums.EDataTypeFilterOption.OTHER,
      value: DataRequestTypeGuidEnum.OTHER
    }
  ]
};

export const statusColumnFilterProps = {
  filterActive: getFilterActiveStatus,
  filterOptions: [
    {
      label: CommonColumnsEnums.EStatusColumnFilterOption.TO_DO,
      value: `${Status.Todo};null`
    },
    {
      label: CommonColumnsEnums.EStatusColumnFilterOption.IN_PROGRESS,
      value: `${Status.InProgress};null`
    },
    {
      label:
        CommonColumnsEnums.EStatusColumnFilterOption
          .IN_PROGRESS_DATA_DELIEVERED,
      value: `${Status.InProgress};${SubStatusEnums.DataDelivered}`
    },
    {
      label: CommonColumnsEnums.EStatusColumnFilterOption.COMPLETE,
      value: `${Status.Complete};null`
    }
  ]
};
