export enum EColumnId {
  ACTIONS = 'actions',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DATA_TYPE = 'dataType',
  DUE_DATE = 'dueDate',
  STATUS = 'status',
  TITLE = 'title'
}

export enum EColumnType {
  ACTIONS_FOR_DATA_REQUEST = 'actionsForDataRequest',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DATA_TYPE = 'dataType',
  DUE_DATE = 'dueDate',
  STATUS = 'status',
  TITLE_WITH_LINK = 'titleWithLink'
}

export enum EDataTypeFilterOption {
  AUDIT_WORKPAPERS = 'auditWorkpapers',
  DOCUMENT_IMAGES = 'documentImages',
  ERP_DATA = 'erpData',
  ORGANIZATIONAL_CHART = 'organizationalChart',
  OTHER = 'other',
  TAX_RETURNS = 'taxReturns'
}

export enum EStatusColumnFilterOption {
  COMPLETE = 'complete',
  IN_PROGRESS = 'inProgress',
  IN_PROGRESS_DATA_DELIEVERED = 'inProgressDataDelivered',
  TO_DO = 'todo'
}
