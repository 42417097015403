import { DataRequestTypesEnums, IDataRequestModalProps } from './utils/';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { withTranslation } from 'react-i18next';

import { EMessageTypes, Stepper, pushToast } from '@ryan/components';

import Modal from '../../../components/Modal';
import UploadModalWarning from '../../../components/UploadModalWarning/UploadModalWarning';
import { useEngagement } from '../../../contexts/EngagementContext';
import { withUser } from '../../../contexts/UserContext';
import {
  FolderSelection,
  IAccount,
  IAttachmentUpdates,
  IDataRequestV2,
  IDirectoryFile,
  IEngagementSummary,
  IErpSystemTypes,
  IFolderTree,
  IJurisdictions,
  ITaxEngineTypes,
  IUserSummary
} from '../../../interfaces';
import ApiService from '../../../services/ApiService';
import { useDSSManager } from '../../../utils/DSS';
import { formatDate } from '../../../utils/formatDate';
import pushServerErrorToast from '../../../utils/pushServerErrorToast';
import DataRequestForm from './DataRequestForm/DataRequestForm';
import { IDataRequestType } from './DataRequestForm/utils';
import FirstStepFormAuditWorkpapers from './FirstStepForms/FirstStepFormAuditWorkpapers/FirstStepFormAuditWorkpapers';
import FirstStepFormDocumentImages from './FirstStepForms/FirstStepFormDocumentImages/FirstStepFormDocumentImages';
import FirstStepFormErp from './FirstStepForms/FirstStepFormErp/FirstStepFormErp';
import FirstStepFormOrgChart from './FirstStepForms/FirstStepFormOrgChart/FirstStepFormOrgChart';
import FirstStepFormOther from './FirstStepForms/FirstStepFormOther/FirstStepFormOther';
import FirstStepFormTaxReturns from './FirstStepForms/FirstStepFormTaxReturns/FirstStepFormTaxReturns';
import SecondStepForm from './SecondStepForm/SecondStepForm';
import { IDateRange } from './SecondStepForm/utils';
import ThirdStepForm from './ThirdStepForm/ThirdStepForm';
import { InitialAttachments, ThirdStepFormEnums } from './ThirdStepForm/utils';

import './DataRequestModal.scss';

export const DataRequestModal: FunctionComponent<IDataRequestModalProps> = ({
  activeView: { customViewGuid },
  isAccountSelect,
  engagementGuid,
  onClose: onCloseProp,
  t: getTextToDisplay
}) => {
  const dss = useDSSManager();
  const { refreshUpdateDate } = useEngagement();

  const [account, setAccount] = useState<IAccount | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  // 0 step data
  const [engagement, setEngagement] = useState<Omit<
    IEngagementSummary,
    'clientName'
  > | null>(null);
  const [previousAccount, setPreviousAccount] = useState<IAccount | null>(null);
  const [previousEngagement, setPreviousEngagement] = useState<Omit<
    IEngagementSummary,
    'clientName'
  > | null>(null);
  const [dataRequestType, setDataRequestType] = useState<string>('');
  const [dataRequestTypes, setDataRequestTypes] = useState<IDataRequestType[]>(
    []
  );
  const [previousDataRequestType, setPreviousDataRequestType] =
    useState<string>('');
  const [otherDrType, setOtherDrType] = useState<string>('');
  // 1st step data
  const [title, setTitle] = useState<string>('');
  const [dataSpecs, setDataSpecs] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [documentType, setDocumentType] = useState<string[]>([]);
  const [transferDestination, setTransferDestination] =
    useState<FolderSelection>(null);
  const [erpSystem, setErpSystem] = useState<IErpSystemTypes | null>(null);
  const [taxEngine, setTaxEngine] = useState<ITaxEngineTypes | null>(null);
  const [jurisdictions, setJurisdictions] = useState<string[]>([]);
  const [jurisdictionsAll, setJurisdictionsAll] = useState<IJurisdictions[]>(
    []
  );
  const [otherDocumentType, setOtherDocumentType] = useState<string>('');
  const [otherERPType, setOtherERPType] = useState<string>('');
  const [otherTaxEngineType, setOtherTaxEngineType] = useState<string>('');
  const [folders, setFolders] = useState<IFolderTree[] | null>(null);
  const [foldersFetching, setFoldersFetching] = useState(false);
  // 2nd step data
  const [dateRanges, setDateRanges] = useState<IDateRange[]>([
    { startDate: null, endDate: null }
  ]);
  // 3rd step data
  const [assignedTo, setAssignedTo] = useState<IUserSummary | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [attachments, setAttachments] =
    useState<IAttachmentUpdates>(InitialAttachments);
  const [shouldAttachFiles, setShouldAttachFiles] = useState<string>(
    ThirdStepFormEnums.NO
  );
  const [existingFile, setExistingFile] = useState<IDirectoryFile[]>([]);

  const fetchFolders = useCallback(
    async (engagementGuid: string) => {
      setFolders(null);
      setFoldersFetching(true);
      const { data: folders } = await ApiService.getEngagementFolders(
        engagementGuid
      );
      //don't do this here, we should filter this like we do Archived folders so that we can see internal folders later
      //const filteredFolders = folders.filter(
      //  folder => folder.folderVisibleToUserTypes !== UserType.Ryan
      //);
      if (engagement && engagement.engagementGuid === engagementGuid) {
        setFolders(folders);
      }
      setFoldersFetching(false);
      return folders;
    },
    [engagement]
  );

  useEffect(() => {
    if (engagement?.engagementGuid) {
      fetchFolders(engagement?.engagementGuid);
    }
  }, [engagement, fetchFolders]);

  const resetFormValues = () => {
    setDateRanges([{ startDate: null, endDate: null }]);
    setDataSpecs('');
    setDescription('');
    setDocumentType([]);
    setOtherDocumentType('');
    setOtherDrType('');
    setOtherERPType('');
    setOtherTaxEngineType('');
    setTitle('');
    setTransferDestination(null);
    setAssignedTo(null);
    setDueDate(null);
    setAttachments(InitialAttachments);
    setShouldAttachFiles(ThirdStepFormEnums.NO);
    setJurisdictions([]);
    setJurisdictionsAll([]);
    setErpSystem(null);
    setTaxEngine(null);
    setExistingFile([]);
  };

  const onClose = useCallback(() => {
    onCloseProp();
    dss.clearUploads();
  }, [dss, onCloseProp]);

  const handleStepClick = (step: number) => {
    if (currentStep === 1) {
      if (previousDataRequestType !== dataRequestType) {
        setDataRequestType(previousDataRequestType);
        resetFormValues();
      }
      if (previousEngagement?.engagementGuid !== engagement?.engagementGuid) {
        setEngagement(previousEngagement);
        resetFormValues();
      }

      if (isAccountSelect) {
        if (previousAccount?.accountGuid !== account?.accountGuid) {
          setAccount(previousAccount);
          resetFormValues();
        }
      }
    }
    setCurrentStep(step);
  };

  const getDrTypeNameById = (
    dataRequestTypeId: string,
    dataRequestTypes: IDataRequestType[]
  ) => {
    const dataRequestType = dataRequestTypes.find(
      type => type.dataRequestTypeGuid === dataRequestTypeId
    );
    return dataRequestType ? dataRequestType.name : 'unknown';
  };

  const setAccountFormValues = (accountObj: IAccount | null) => {
    if (previousAccount === null) {
      setAccount(accountObj);
    }
    if (accountObj !== null) {
      setPreviousAccount(accountObj);
    }
  };

  const setDataRequestTypeFormValues = (type: string) => {
    if (previousDataRequestType === '') {
      setDataRequestType(type);
    }
    setPreviousDataRequestType(type);
  };

  const setEngagementFormValues = (
    engagementObj: Omit<IEngagementSummary, 'clientName'> | null
  ) => {
    if (previousEngagement === null) {
      setEngagement(engagementObj);
    }
    if (engagementObj !== null) {
      setPreviousEngagement(engagementObj);
    }
  };

  const handleFormSubmit = async () => {
    const transformedDateRanges = dateRanges.map(range => ({
      dateStart: formatDate(range.startDate as Date, false, 'YYYY/MM/DD'),
      dateEnd: formatDate(range.endDate as Date, false, 'YYYY/MM/DD')
    }));

    const transformJurisdictions = (
      jurisdictions: string[]
    ): { jurisdictionGuid: string }[] => {
      return jurisdictions.map(jurisdiction => ({
        jurisdictionGuid: jurisdiction
      }));
    };

    const transformDocumentTypes = (
      documentTypes: string[]
    ): { documentTypeGuid: string }[] => {
      return documentTypes.map(documentType => ({
        documentTypeGuid: documentType
      }));
    };

    const dataRequest = {
      attachmentsToUpsert: attachments,
      assignedToUserGuid: assignedTo?.userGuid,
      dateRange: transformedDateRanges,
      dataRequestTypeGuid: dataRequestType,
      dataSpecs: dataSpecs || null,
      defaultFolderGuid: transferDestination
        ? transferDestination.folderGuid
        : null,
      description: description || null,
      documentTypes: transformDocumentTypes(documentType),
      dueDate: dueDate ? formatDate(dueDate, false, 'YYYY/MM/DD') : undefined,
      engagementGuid: engagement?.engagementGuid,
      erpGuid: erpSystem?.erpGuid,
      folder: transferDestination,
      jurisdictions: transformJurisdictions(jurisdictions),
      otherDocumentType: otherDocumentType || null,
      otherErpSystem: otherERPType || null,
      otherTaxEngine: otherTaxEngineType || null,
      taxEngineGuid: taxEngine?.taxEngineGuid,
      title: title
    };
    try {
      const promise = await ApiService.createDataRequestV2(dataRequest);
      const savedDataRequest = promise.data;
      const { assignedToName, title } = savedDataRequest;

      pushToast({
        type: EMessageTypes.SUCCESS,
        title: getTextToDisplay('dataRequest.modal.new.success.title'),
        content: getTextToDisplay(
          'dataRequest.modal.new.success.content-assigned-x',
          {
            title,
            assignedToName
          }
        )
      });

      dss.clearUploads();
      refreshUpdateDate?.(dataRequest?.engagementGuid);
      onCloseProp(savedDataRequest as IDataRequestV2);
    } catch (error) {
      pushServerErrorToast();
    }
  };

  const renderStepContent = () => {
    const dataRequestTypeName = getDrTypeNameById(
      dataRequestType,
      dataRequestTypes
    );

    switch (currentStep) {
      case 1:
        return (
          <DataRequestForm
            {...(isAccountSelect && { isAccountSelect, account })}
            customViewGuid={customViewGuid}
            dataRequestType={dataRequestType}
            dataRequestTypes={dataRequestTypes}
            engagement={engagement}
            engagementGuid={engagementGuid}
            onNextStepClick={handleStepClick}
            otherDrType={otherDrType}
            setAccount={setAccountFormValues}
            setDataRequestType={setDataRequestTypeFormValues}
            setDataRequestTypes={setDataRequestTypes}
            setEngagement={setEngagementFormValues}
            setOtherDrType={setOtherDrType}
          />
        );
      case 2:
        if (dataRequestTypeName === DataRequestTypesEnums.DOCUMENT_IMAGES) {
          return (
            <FirstStepFormDocumentImages
              dataRequestTypeName={dataRequestTypeName}
              dataSpecs={dataSpecs}
              description={description}
              documentType={documentType}
              engagement={engagement}
              folders={folders}
              foldersFetching={foldersFetching}
              onNextStepClick={handleStepClick}
              otherDocumentType={otherDocumentType}
              setDataSpecs={setDataSpecs}
              setDescription={setDescription}
              setDocumentType={setDocumentType}
              setOtherDocumentType={setOtherDocumentType}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              title={title}
              transferDestination={transferDestination}
            />
          );
        } else if (dataRequestTypeName === DataRequestTypesEnums.ERP_DATA) {
          return (
            <FirstStepFormErp
              dataRequestTypeName={dataRequestTypeName}
              description={description}
              engagement={engagement}
              erpSystem={erpSystem}
              folders={folders}
              foldersFetching={foldersFetching}
              onNextStepClick={handleStepClick}
              otherERPType={otherERPType}
              otherTaxEngineType={otherTaxEngineType}
              setDescription={setDescription}
              setErpSystem={setErpSystem}
              setOtherERPType={setOtherERPType}
              setOtherTaxEngineType={setOtherTaxEngineType}
              setTaxEngine={setTaxEngine}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              taxEngine={taxEngine}
              title={title}
              transferDestination={transferDestination}
            />
          );
        } else if (
          dataRequestTypeName === DataRequestTypesEnums.ORGANISATIONAL_CHART
        ) {
          return (
            <FirstStepFormOrgChart
              dataRequestTypeName={dataRequestTypeName}
              description={description}
              engagement={engagement}
              folders={folders}
              foldersFetching={foldersFetching}
              onNextStepClick={handleStepClick}
              setDescription={setDescription}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              title={title}
              transferDestination={transferDestination}
            />
          );
        } else if (dataRequestTypeName === DataRequestTypesEnums.TAX_RETURNS) {
          return (
            <FirstStepFormTaxReturns
              dataRequestTypeName={dataRequestTypeName}
              dataSpecs={dataSpecs}
              description={description}
              engagement={engagement}
              folders={folders}
              foldersFetching={foldersFetching}
              jurisdictions={jurisdictions}
              jurisdictionsAll={jurisdictionsAll}
              onNextStepClick={handleStepClick}
              setDataSpecs={setDataSpecs}
              setDescription={setDescription}
              setJurisdictions={setJurisdictions}
              setJurisdictionsAll={setJurisdictionsAll}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              title={title}
              transferDestination={transferDestination}
            />
          );
        } else if (
          dataRequestTypeName === DataRequestTypesEnums.AUDIT_WORKPAPERS
        ) {
          return (
            <FirstStepFormAuditWorkpapers
              dataRequestTypeName={dataRequestTypeName}
              dataSpecs={dataSpecs}
              description={description}
              engagement={engagement}
              folders={folders}
              foldersFetching={foldersFetching}
              jurisdictions={jurisdictions}
              jurisdictionsAll={jurisdictionsAll}
              onNextStepClick={handleStepClick}
              setDataSpecs={setDataSpecs}
              setDescription={setDescription}
              setJurisdictions={setJurisdictions}
              setJurisdictionsAll={setJurisdictionsAll}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              title={title}
              transferDestination={transferDestination}
            />
          );
        } else if (dataRequestTypeName === DataRequestTypesEnums.OTHER) {
          return (
            <FirstStepFormOther
              dataRequestTypeName={dataRequestTypeName}
              dataSpecs={dataSpecs}
              description={description}
              engagement={engagement}
              folders={folders}
              foldersFetching={foldersFetching}
              jurisdictions={jurisdictions}
              jurisdictionsAll={jurisdictionsAll}
              onNextStepClick={handleStepClick}
              setDataSpecs={setDataSpecs}
              setDescription={setDescription}
              setJurisdictions={setJurisdictions}
              setJurisdictionsAll={setJurisdictionsAll}
              setTitle={setTitle}
              setTransferDestination={setTransferDestination}
              title={title}
              transferDestination={transferDestination}
            />
          );
        }

        return null;
      case 3:
        return (
          <SecondStepForm
            dataRequestTypeName={dataRequestTypeName}
            dateRanges={dateRanges}
            engagement={engagement}
            onNextStepClick={handleStepClick}
            setDateRanges={setDateRanges}
            title={title}
          />
        );
      case 4:
        return (
          <ThirdStepForm
            assignedTo={assignedTo}
            attachments={attachments}
            customViewGuid={customViewGuid}
            dataRequestTypeName={dataRequestTypeName}
            dss={dss}
            dueDate={dueDate}
            engagement={engagement}
            existingFile={existingFile}
            onNextStepClick={handleStepClick}
            onSubmitted={handleFormSubmit}
            setAssignedTo={setAssignedTo}
            setAttachments={setAttachments}
            setDueDate={setDueDate}
            setExistingFile={setExistingFile}
            setShouldAttachFiles={setShouldAttachFiles}
            shouldAttachFiles={shouldAttachFiles}
            title={title}
          />
        );
      default:
        return null;
    }
  };

  return (
    <UploadModalWarning dss={dss} onClose={onClose}>
      {({ dss, warning, onEscape }) => (
        <Modal
          className="create-data-request-modal"
          onClose={onEscape}
          open
          title={getTextToDisplay('modal.dataRequestModal.title')}
        >
          {warning}
          {currentStep > 1 && (
            <Stepper
              currentStep={currentStep}
              onStepClick={step => setCurrentStep(step)}
              orientation="horizontal"
              totalSteps={4}
            />
          )}
          {renderStepContent()}
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(withUser(DataRequestModal));
