import React, { useCallback, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  EButtonSizes,
  EButtonVariant,
  EMessageTypes,
  MentionsValue,
  Message,
  pushToast
} from '@ryan/components';

import { UserAutocomplete } from '../../../../components/AutocompleteAjax';
import CommentInput from '../../../../components/Comments/CommentInput';
import { makeOnSuggestionsRequested } from '../../../../components/Comments/CommentInput/CommentInput';
import ManageAttachments from '../../../../components/ManageAttachments';
import Modal from '../../../../components/Modal';
import UploadModalWarning from '../../../../components/UploadModalWarning/UploadModalWarning';
import {
  IAttachmentUpdates,
  IDataRequestV2,
  IDataRequestV2UploadFiles,
  IEngagement,
  IUserSummary,
  Status
} from '../../../../interfaces';
import ApiService from '../../../../services/ApiService';
import { useDSSManager } from '../../../../utils/DSS';
import {
  Formik,
  formikAutocompleteAjaxProps,
  formikCommentProps,
  formikFieldProps,
  yup
} from '../../../../utils/forms';
import pushServerErrorToast from '../../../../utils/pushServerErrorToast';

import './AssignDataUploadModal.scss';

interface IAssignDataUploadModalValues {
  attachments: IAttachmentUpdates;
  comment: MentionsValue;
  user: IUserSummary | null;
}

interface IAssignDataUploadResponse {
  assignedToUserName: string;
}

interface IAssignDataUploadModalProps extends WithTranslation {
  engagement?: IEngagement | null;
  dataRequest: IDataRequestV2 | null;
  open: boolean;
  onClose: (uploadedData?: boolean) => void;
}

const initialValues: IAssignDataUploadModalValues = {
  attachments: {
    addUploaded: [],
    deleteAttachments: [],
    addExisting: []
  },
  comment: new MentionsValue(),
  user: null
};

const maxLengthComment = 250;

const AssignDataUploadModal: React.FC<IAssignDataUploadModalProps> = ({
  t: getTextToDisplay,
  dataRequest,
  open,
  onClose
}) => {
  const dss = useDSSManager();
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [submitPromise, setSubmitPromise] = useState<Promise<any> | null>(null);
  const [message, setMessage] = useState<React.ReactNode | null>(null);

  const schema = yup.object({
    attachments: yup.object<IAttachmentUpdates>().nullable(),
    comment: yup.mixed().validateCommentLength(
      maxLengthComment,
      getTextToDisplay('dataRequest.ryanUploadModal.commentMaxLength', {
        length: maxLengthComment
      })
    ),
    user: yup
      .object<IUserSummary>()
      .nullable()
      .required(getTextToDisplay('dataRequest.ryanUploadModal.userRequired'))
  });

  const handleFetchAssignableUsers = async (query: string) => {
    try {
      const response = await ApiService.getEngagementDataRequestAssignableUsers(
        dataRequest?.engagementGuid || '',
        query
      );

      return response.data;
    } catch (error) {
      if (!ApiService.isCancel(error)) {
        pushServerErrorToast();
      }
    }
    return [];
  };

  const close = useCallback(
    (withChanges: boolean) => {
      if (submitPromise === null) {
        setMessage(null);
        dss.clearUploads();
        onClose(withChanges);
      }
    },
    [submitPromise, dss, onClose]
  );

  const uploadData = useCallback(
    async (
      dataRequest: IDataRequestV2,
      values: IAssignDataUploadModalValues
    ) => {
      try {
        const response = await ApiService.uploadDataRequestsV2Files({
          assignedToUserGuid: values.user?.userGuid,
          attachmentsToUpsert: values.attachments,
          comment: values.comment.toJSON().text,
          engagementGuid: dataRequest.engagementGuid,
          queueItemGuid: dataRequest.queueItemGuid,
          status: Status.InProgress,
          substatus: null
        } as IDataRequestV2UploadFiles);

        const { assignedToUserName } =
          response.data as IAssignDataUploadResponse;

        pushToast({
          type: EMessageTypes.SUCCESS,
          content: getTextToDisplay(
            'dataRequest.assignDataUploadModal.success',
            {
              title: dataRequest.title,
              userName: assignedToUserName
            }
          )
        });

        setSubmitPromise(null);
        close(true);
      } catch (error) {
        setMessage(
          <Message title={getTextToDisplay('serverError.title')} type="error">
            {getTextToDisplay('serverError.content')}
          </Message>
        );

        pushToast({
          type: EMessageTypes.ERROR,
          content: getTextToDisplay('dataRequest.assignDataUploadModal.error', {
            title: dataRequest.engagementDisplayNameShort
          })
        });

        setSubmitPromise(null);
        throw error;
      }
    },
    [getTextToDisplay, close]
  );

  const handleSubmit = useCallback(
    (values: IAssignDataUploadModalValues) => {
      if (dataRequest) {
        setMessage(null);
        setSubmitPromise(uploadData(dataRequest, values));
      }
    },
    [dataRequest, uploadData]
  );

  if (!dataRequest) return null;

  return (
    <UploadModalWarning dss={dss} onClose={() => close(false)}>
      {({ dss, warning, onEscape, onCancel }) => (
        <Modal
          className="assign-data-upload-modal"
          onClose={onEscape}
          open={open}
          title={getTextToDisplay('dataRequest.assignDataUploadModal.title', {
            title: dataRequest.title
          })}
        >
          {warning}
          {message}
          <div ref={errorContainerRef} />
          <Formik<IAssignDataUploadModalValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {formik => {
              const disabled = formik.values.user === null;

              return (
                <form
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  ref={formRef}
                >
                  <div className="assign-data-upload-modal__form">
                    <UserAutocomplete
                      {...formikAutocompleteAjaxProps('user', formik)}
                      engagementGuid={dataRequest.engagementGuid}
                      label={getTextToDisplay(
                        'dataRequest.modal.fields.assignedTo.label'
                      )}
                      onFetchOptions={handleFetchAssignableUsers}
                    />
                    <CommentInput
                      {...formikCommentProps('comment', formik)}
                      boundingParentRef={formRef}
                      label={getTextToDisplay(
                        'dataRequest.modal.fields.comment.label'
                      )}
                      makeOnSuggestionsRequestedCallback={() =>
                        makeOnSuggestionsRequested(
                          dataRequest.engagementGuid,
                          user => user.canBeMentionedInDRComment
                        )
                      }
                    />
                    <ManageAttachments
                      {...formikFieldProps('attachments', formik)}
                      attachments={dataRequest.attachments}
                      dss={dss}
                      engagementGuid={dataRequest.engagementGuid}
                      engagementName={dataRequest.engagementDisplayNameShort}
                      multiple
                      onChange={updates =>
                        formik.setFieldValue('attachments', updates)
                      }
                      shouldDelete={false}
                      showLabel
                      smallDropzone
                      value={formik.values.attachments}
                    />
                  </div>
                  <div className="assign-data-upload-modal__buttons">
                    <ButtonGroup>
                      <Button
                        disabled={disabled}
                        loading={submitPromise}
                        size={EButtonSizes.LARGE}
                        text={getTextToDisplay('Submit')}
                        type="submit"
                        variant={EButtonVariant.PRIMARY}
                      />
                      <Button
                        disabled={submitPromise !== null}
                        onClick={onCancel}
                        size={EButtonSizes.LARGE}
                        text={getTextToDisplay('Cancel')}
                        variant={EButtonVariant.SECONDARY}
                      />
                    </ButtonGroup>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </UploadModalWarning>
  );
};

export default withTranslation()(AssignDataUploadModal);
