import { makeTableCheckboxFilter } from '@ryan/components';

import { EntityType, Status } from '../../../../interfaces';
import { DataRequestTypeGuidEnum } from '../../../../utils/enums/DataRequestsEnums';
import { StatusAndSubstatus } from '../../../../utils/enums/StatusAndSubstatus';
import { SubStatusEnums } from '../../../../utils/enums/SubStatusEnums';
import * as CommonColumnUtils from '../CommonColumns/CommonColumns.utils';
import * as UseWorkListColumnsEnums from './useWorkListColumns.enums';
import * as UseWorkListColumnsInterfaces from './useWorkListColumns.interfaces';

const columnTemplate: UseWorkListColumnsInterfaces.TWorklistColumn[] = [
  {
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.TITLE,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.TITLE,
    sortable: true
  },
  {
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.PROJECT,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.PROJECT,
    sortable: true
  },
  {
    filterActive: CommonColumnUtils.statusColumnFilterProps.filterActive,
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.TYPE,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.TYPE
  },
  {
    filterActive: CommonColumnUtils.statusColumnFilterProps.filterActive,
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.STATUS,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.STATUS
  },
  {
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.ASSIGNED_TO,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.ASSIGNED_TO,
    sortable: true
  },
  {
    id: UseWorkListColumnsEnums.WorklistColumnIdEnums.DUE_DATE,
    label: UseWorkListColumnsEnums.WorklistColumnIdEnums.DUE_DATE,
    sortable: true
  },
  { id: UseWorkListColumnsEnums.WorklistColumnIdEnums.COMMENT },
  { id: UseWorkListColumnsEnums.WorklistColumnIdEnums.ACTION }
];

export const getColumnTemplate: UseWorkListColumnsInterfaces.IGetColumnTemplate =
  ({ getCheckboxLabel, statusFilters, typeFilters }) => {
    const modified = [...columnTemplate];

    const statusIndex = modified.findIndex(
      ({ id }) => id === UseWorkListColumnsEnums.WorklistColumnIdEnums.STATUS
    );
    const typeIndex = modified.findIndex(
      ({ id }) => id === UseWorkListColumnsEnums.WorklistColumnIdEnums.TYPE
    );

    const typeFiltersMap = {
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.AUDIT_WORKPAPERS]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.AUDIT_WORKPAPERS}`
          ),
          value: DataRequestTypeGuidEnum.AUDIT_WORKPAPERS
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.DOCUMENT_IMAGES]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.DOCUMENT_IMAGES}`
          ),
          value: DataRequestTypeGuidEnum.DOCUMENT_IMAGES
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.ERP_DATA]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.ERP_DATA}`
          ),
          value: DataRequestTypeGuidEnum.ERP_DATA
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.ORGANIZATIONAL_CHART]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.ORGANIZATIONAL_CHART}`
          ),
          value: DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.TAX_RETURNS]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.TAX_RETURNS}`
          ),
          value: DataRequestTypeGuidEnum.TAX_RETURNS
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.OTHER]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.OTHER}`
          ),
          value: DataRequestTypeGuidEnum.OTHER
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistTypeFilterEnums.TASKS]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.type.${UseWorkListColumnsEnums.WorklistTypeFilterEnums.TASKS}`
          ),
          value: EntityType.Task.toString()
        },
        isInDataSet: false
      }
    };

    typeFilters.forEach(({ entityTypeId, dataRequestTypeGuid }) => {
      let filterToIncude;

      if (entityTypeId === EntityType.Task) {
        filterToIncude = UseWorkListColumnsEnums.WorklistTypeFilterEnums.TASKS;
      } else if (
        dataRequestTypeGuid === DataRequestTypeGuidEnum.AUDIT_WORKPAPERS
      ) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistTypeFilterEnums.AUDIT_WORKPAPERS;
      } else if (dataRequestTypeGuid === DataRequestTypeGuidEnum.ERP_DATA) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistTypeFilterEnums.ERP_DATA;
      } else if (
        dataRequestTypeGuid === DataRequestTypeGuidEnum.DOCUMENT_IMAGES
      ) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistTypeFilterEnums.DOCUMENT_IMAGES;
      } else if (
        dataRequestTypeGuid === DataRequestTypeGuidEnum.ORGANIZATIONAL_CHART
      ) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistTypeFilterEnums.ORGANIZATIONAL_CHART;
      } else if (dataRequestTypeGuid === DataRequestTypeGuidEnum.TAX_RETURNS) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistTypeFilterEnums.TAX_RETURNS;
      } else if (dataRequestTypeGuid === DataRequestTypeGuidEnum.OTHER) {
        filterToIncude = UseWorkListColumnsEnums.WorklistTypeFilterEnums.OTHER;
      }

      if (!filterToIncude || typeFiltersMap[filterToIncude].isInDataSet) {
        return;
      }

      typeFiltersMap[filterToIncude].isInDataSet = true;
    });

    modified[typeIndex] = {
      ...modified[typeIndex],
      filter: makeTableCheckboxFilter([
        {
          label: getCheckboxLabel(`filter.type.checkboxGroups.dataRequests`),
          options: [
            UseWorkListColumnsEnums.WorklistTypeFilterEnums.AUDIT_WORKPAPERS,
            UseWorkListColumnsEnums.WorklistTypeFilterEnums.ERP_DATA,
            UseWorkListColumnsEnums.WorklistTypeFilterEnums.DOCUMENT_IMAGES,
            UseWorkListColumnsEnums.WorklistTypeFilterEnums
              .ORGANIZATIONAL_CHART,
            UseWorkListColumnsEnums.WorklistTypeFilterEnums.TAX_RETURNS,
            UseWorkListColumnsEnums.WorklistTypeFilterEnums.OTHER
          ]
            .filter(filterOption => typeFiltersMap[filterOption].isInDataSet)
            .map(filterOption => typeFiltersMap[filterOption].checkboxOption)
        },
        {
          label: getCheckboxLabel('filter.type.checkboxGroups.tasks'),
          options: [UseWorkListColumnsEnums.WorklistTypeFilterEnums.TASKS]
            .filter(filterOption => typeFiltersMap[filterOption].isInDataSet)
            .map(filterOption => typeFiltersMap[filterOption].checkboxOption)
        }
      ])
    };

    const statusFiltersMap = {
      [UseWorkListColumnsEnums.WorklistStatusFilterEnums.IN_PROGRESS]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.status.${UseWorkListColumnsEnums.WorklistStatusFilterEnums.IN_PROGRESS}`
          ),
          value: StatusAndSubstatus.InProgress
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistStatusFilterEnums
        .IN_PROGRESS_DATA_DELIVERED]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.status.${UseWorkListColumnsEnums.WorklistStatusFilterEnums.IN_PROGRESS_DATA_DELIVERED}`
          ),
          value: StatusAndSubstatus.InProgressDataDelivered
        },
        isInDataSet: false
      },
      [UseWorkListColumnsEnums.WorklistStatusFilterEnums.TODO]: {
        checkboxOption: {
          label: getCheckboxLabel(
            `filter.status.${UseWorkListColumnsEnums.WorklistStatusFilterEnums.TODO}`
          ),
          value: StatusAndSubstatus.ToDo
        },
        isInDataSet: false
      }
    };

    statusFilters.forEach(({ statusId, subStatusId }) => {
      let filterToIncude;

      if (statusId === Status.Todo) {
        filterToIncude = UseWorkListColumnsEnums.WorklistStatusFilterEnums.TODO;
      } else if (statusId === Status.InProgress && subStatusId === null) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistStatusFilterEnums.IN_PROGRESS;
      } else if (
        statusId === Status.InProgress &&
        subStatusId === SubStatusEnums.DataDelivered
      ) {
        filterToIncude =
          UseWorkListColumnsEnums.WorklistStatusFilterEnums
            .IN_PROGRESS_DATA_DELIVERED;
      }

      if (!filterToIncude || statusFiltersMap[filterToIncude].isInDataSet) {
        return;
      }

      statusFiltersMap[filterToIncude].isInDataSet = true;
    });

    modified[statusIndex] = {
      ...modified[statusIndex],
      filter: makeTableCheckboxFilter(
        [
          UseWorkListColumnsEnums.WorklistStatusFilterEnums.TODO,
          UseWorkListColumnsEnums.WorklistStatusFilterEnums.IN_PROGRESS,
          UseWorkListColumnsEnums.WorklistStatusFilterEnums
            .IN_PROGRESS_DATA_DELIVERED
        ]
          .filter(filterOption => statusFiltersMap[filterOption].isInDataSet)
          .map(filterOption => statusFiltersMap[filterOption].checkboxOption)
      )
    };

    return modified;
  };
