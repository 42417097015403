export enum WorklistColumnIdEnums {
  ACTION = 'action',
  ASSIGNED_TO = 'assignedTo',
  COMMENT = 'comment',
  DUE_DATE = 'dueDate',
  PROJECT = 'engagementDisplayNameShort',
  STATUS = 'status',
  TITLE = 'title',
  TYPE = 'entityType'
}

export enum WorklistTypeFilterEnums { // TODO: Table Refactor: Update enum name
  AUDIT_WORKPAPERS = 'auditWorkpapers',
  DOCUMENT_IMAGES = 'documentImages',
  ERP_DATA = 'erpData',
  ORGANIZATIONAL_CHART = 'organizationalChart',
  TAX_RETURNS = 'taxReturns',
  OTHER = 'other',
  TASKS = 'tasks'
}
export enum WorklistStatusFilterEnums { // TODO: Table Refactor: Update enum name
  TODO = 'todo',
  IN_PROGRESS = 'inProgress',
  IN_PROGRESS_DATA_DELIVERED = 'inProgressDataDelivered'
}
