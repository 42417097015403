import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, EButtonVariant } from '@ryan/components';

import { WithUser, withUser } from '../../../contexts/UserContext';
import {
  Feature,
  FolderSelection,
  IFolder,
  IFolderTree,
  UserType
} from '../../../interfaces';
import Modal from '../../Modal';
import ConfirmationModal from '../../Modal/ConfirmationModal/ConfirmationModal';
import SelectFolder from '../../SelectFolder/SelectFolder';
import { FileBatch } from '../utils/FileBatch';
import { RYAN_INTERNAL } from '../utils/FileDirectoryEnums';

import './FileMoveModal.scss';

interface IFileMoveModalProps extends WithUser {
  files: FileBatch | null;
  folderDirectory: IFolderTree[] | null; // null if loading
  folderPath: IFolder[];
  rootName: string; // display name when selection is null
  loading: Promise<any> | null;
  onSubmit: (folder: FolderSelection) => any;
  onCancel: () => void;
}

export const FileMoveModal: FunctionComponent<IFileMoveModalProps> = ({
  files,
  folderDirectory,
  folderPath,
  isFeatureToggled,
  rootName,
  loading,
  onSubmit,
  onCancel
}) => {
  const { t: getTextToDisplay } = useTranslation();
  const [destinationFolder, setDestinationFolder] =
    useState<FolderSelection>(null);
  const [currentFolder, setCurrentFolder] = useState<FolderSelection>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(true);
  const [selectFolderError, setSelectFolderError] = useState<string | null>(
    null
  );
  const isCreating =
    destinationFolder !== null && destinationFolder.folderGuid === null;

  const handleSave = async () => {
    const folderNameError = await onSubmit(destinationFolder);

    if (folderNameError) {
      setSelectFolderError(folderNameError);
    }
  };
  const isInternalFilesFunctionalityVisible = isFeatureToggled(
    Feature.InternalFiles
  );

  // a list of selected folders to be moved; prevent selected folders from
  // being moved within themselves
  const disabledFolders: string[] = useMemo(
    () => files?.folders.map(folder => folder.folderGuid) || [],
    [files]
  );

  const foldersWithoutInternal = (folderDirectory || []).filter(
    folder => folder.folderName !== RYAN_INTERNAL
  );

  // check if moving internal files or folders to external folder
  const checkIfMovingInternalFiles = () =>
    (files!.folders.some(
      folder => folder.folderVisibleToUserTypes === UserType.Ryan
    ) ||
      files!.files.some(file => file.visibleToUserTypes === UserType.Ryan)) &&
    destinationFolder?.folderVisibleToUserTypes !== UserType.Ryan;

  const checkIfMovingLockedFiles = () =>
    files!.files.some(file => file.isLocked === true);

  useEffect(() => {
    if (files !== null) {
      if (folderPath.length > 0) {
        const {
          parentFolderGuid,
          folderGuid,
          folderName,
          folderVisibleToUserTypes
        } = folderPath[folderPath.length - 1];
        const folder: FolderSelection = {
          parentFolderGuid,
          folderGuid,
          folderName,
          folderVisibleToUserTypes
        };

        setCurrentFolder(folder);
        setDestinationFolder(folder);
      }
    }
  }, [files, folderPath]);

  const onSelectFolderChange = (folder: FolderSelection) => {
    if (selectFolderError) {
      setSelectFolderError(null);
    }

    setDestinationFolder(folder);
  };

  if (files) {
    return (
      <>
        <Modal
          className="file-move-modal"
          onClose={onCancel}
          open={isMoveModalOpen}
          title={getTextToDisplay('file.moveModal.title', {
            count: files.filesCount
          })}
        >
          {folderDirectory ? (
            <SelectFolder
              autoOpen
              disabled={loading !== null}
              disabledFolders={disabledFolders}
              feedback={selectFolderError}
              folders={
                checkIfMovingLockedFiles() ||
                !isInternalFilesFunctionalityVisible
                  ? foldersWithoutInternal
                  : folderDirectory
              }
              invalid={selectFolderError !== null}
              moveFileCount={files.filesCount}
              onChange={onSelectFolderChange}
              rootName={rootName}
              value={destinationFolder}
            />
          ) : (
            <div>
              <div className="ry-skeleton sk-field-label" />
              <div className="ry-skeleton sk-field" />
            </div>
          )}
          <ButtonGroup>
            <Button
              disabled={
                selectFolderError !== null ||
                (destinationFolder === null && currentFolder === null) ||
                destinationFolder?.folderGuid === currentFolder?.folderGuid
              }
              loading={loading}
              onClick={() => {
                if (checkIfMovingInternalFiles()) {
                  setIsConfirmationModalOpen(true);
                  setIsMoveModalOpen(false);
                } else {
                  handleSave();
                }
              }}
              text={getTextToDisplay(isCreating ? 'Done' : 'Move')}
              type="submit"
              variant={EButtonVariant.PRIMARY}
            />
            <Button
              disabled={loading !== null}
              onClick={onCancel}
              text={getTextToDisplay('Cancel')}
              variant={EButtonVariant.SECONDARY}
            />
          </ButtonGroup>
        </Modal>
        {isConfirmationModalOpen && (
          <ConfirmationModal
            cancelTextToDisplay={getTextToDisplay('Cancel')}
            confirmationMessage={getTextToDisplay(
              files.filesCount > 0
                ? files.filesCount > 1
                  ? 'file.moveModal.externalMovePluralDescription'
                  : 'file.moveModal.externalMoveSingleDescription'
                : files.folders.length > 1
                ? 'file.moveModal.externalMoveFolderPluralDescription'
                : 'file.moveModal.externalMoveFolderDescription'
            )}
            isPositive
            onClose={() => {
              setIsConfirmationModalOpen(false);
              setIsMoveModalOpen(true);
            }}
            onSubmit={handleSave}
            submitTextToDisplay={getTextToDisplay('file.moveModal.move')}
            title={getTextToDisplay(
              files.filesCount > 0
                ? files.filesCount > 1
                  ? 'file.moveModal.externalMovePluralTitle'
                  : 'file.moveModal.externalMoveSingleTitle'
                : files.folders.length > 1
                ? 'file.moveModal.externalMoveFolderPluralTitle'
                : 'file.moveModal.externalMoveFolderTitle',
              {
                count: files.filesCount,
                destinationName: destinationFolder?.folderName ?? rootName,
                folderCount: files.folders.length
              }
            )}
          />
        )}
      </>
    );
  }

  return null;
};

export default withUser(FileMoveModal);
