import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabManager, TabPanel, Tabs } from '@ryan/components';
import { ITab } from '@ryan/components/dist/lib/components/Tabs/ITab';

import DocumentTitle from '../../components/DocumentTitle';
import { useUser } from '../../contexts/UserContext';
import WorklistMine from './WorklistMine/WorklistMine';

const Worklist = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>('mine');

  const { permissionService } = useUser();
  const { t: getTextToDisplay } = useTranslation();

  const ROOT_TO_TEXT = 'worklistPage';

  const isRyanUser = permissionService.isRyan();

  const tabs: ITab[] = [
    {
      tabKey: 'mine',
      text: 'Mine',
      disabled: false
    },
    {
      tabKey: 'myTeam',
      text: 'My Team',
      disabled: false
    },
    {
      tabKey: 'client',
      text: isRyanUser ? 'Client' : 'Ryan',
      disabled: false
    },
    {
      tabKey: 'all',
      text: 'All',
      disabled: false
    }
  ];

  const handleTabChange = (tabKey: string) => {
    setActiveTabKey(tabKey);
  };

  return (
    <div className="worklist">
      <DocumentTitle title={getTextToDisplay(`${ROOT_TO_TEXT}.title`)} />
      <h1 className="ry-h1">{getTextToDisplay(`${ROOT_TO_TEXT}.title`)}</h1>
      {/* V2 */}
      <TabManager
        activeTabKey={activeTabKey}
        onTabChange={handleTabChange as any}
      >
        <Tabs tabs={tabs} />
        <hr />
        <TabPanel tabKey="mine">
          <WorklistMine activeTabKey={activeTabKey} />
        </TabPanel>
        <TabPanel tabKey="myTeam">
          <WorklistMine activeTabKey={activeTabKey} />
        </TabPanel>
        <TabPanel tabKey="client">
          <WorklistMine activeTabKey={activeTabKey} />
        </TabPanel>
        <TabPanel tabKey="all">
          <WorklistMine activeTabKey={activeTabKey} />
        </TabPanel>
      </TabManager>
    </div>
  );
};

export default Worklist;
