import { useUser } from 'contexts/UserContext';
import { IAddress, IContactInfoForm, IUser } from 'interfaces';
import { Formik, yup } from 'utils/forms';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Card } from '@ryan/components';

import UploadProfilePictureModal from '../../../components/Modal/UploadProfilePictureModal/UploadProfilePictureModal';
import ContactInformationForm from './ContactInformationForm';
import { ContactInformationSkeleton } from './ContactInformationSkeleton';
import IContactInformationFormValues from './IContactInformationFormValues';

interface IContactInformationProps {
  selectedUser: IUser | null;
  onSubmit: (values: IContactInfoForm) => void;
}

/**
 * This is the parent wrapper for the ContactInformationForm.
 * Initial values are kept here, as well as validation.
 */
const ContactInformation: React.FC<IContactInformationProps> = ({
  selectedUser,
  onSubmit
}) => {
  const { isAppReadOnly, onUserUpdate, permissionService } = useUser();
  const history = useHistory();
  const { t } = useTranslation();
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const isLoading = selectedUser === null;

  const isSameAddressValidator = useCallback(
    (value: string) => isSameAddress || typeof value !== 'undefined',
    [isSameAddress]
  );

  const initialValues: IContactInformationFormValues =
    useMemo((): IContactInformationFormValues => {
      const user = selectedUser;

      /**
       * Generates initial values for user address. `stateLabel` will be mapped
       * from `stateCode` in the backend.
       */
      const getAddress = (key: 'officeAddress' | 'mailingAddress') => ({
        addressLine1: user?.[key]?.addressLine1 || '',
        addressLine2: user?.[key]?.addressLine2 || '',
        city: user?.[key]?.city || '',
        country: user?.[key]?.country || '',
        stateCode: user?.[key]?.stateCode || '',
        stateLabel: '',
        postalCode: user?.[key]?.postalCode || ''
      });
      const mailingAddress = getAddress('mailingAddress');
      const officeAddress = getAddress('officeAddress');

      return {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        title: user?.title || '',
        mobilePhone: user?.mobilePhone || '',
        officePhone: user?.officePhone || '',
        email: user?.email || '',
        officeAddress,
        mailingAddress,
        isSameAddress:
          officeAddress.addressLine1 === mailingAddress.addressLine1 &&
          officeAddress.city === mailingAddress.city &&
          officeAddress.country === mailingAddress.country &&
          officeAddress.stateCode === mailingAddress.stateCode &&
          officeAddress.postalCode === mailingAddress.postalCode
      };
    }, [selectedUser]);

  const schema = useMemo(
    () =>
      yup.object<IContactInformationFormValues>({
        firstName: yup
          .string()
          .required(t('First Name', { context: 'required' })),
        lastName: yup
          .string()
          .required(t('Last Name', { context: 'required' })),
        title: yup.string().required(t('Title', { context: 'required' })),
        mobilePhone: yup.string(),
        officePhone: yup
          .string()
          .required(t('Office Number', { context: 'required' })),
        email: yup.string(),
        mailingAddress: yup.object<IAddress>({
          addressLine1: yup
            .string()
            .required(t('Address', { context: 'required' })),
          addressLine2: yup.string(),
          city: yup.string().required(t('City', { context: 'required' })),
          country: yup.string().required(t('Country', { context: 'required' })),
          stateCode: yup.string(),
          stateLabel: yup.string(),
          postalCode: yup
            .string()
            .required(t('Zip/Postal Code', { context: 'required' }))
        }),
        isSameAddress: yup.boolean(),
        officeAddress: yup.object<IAddress>({
          addressLine1: yup
            .string()
            .test(
              'addressLine1',
              t('Address', { context: 'required' }),
              (value: string) => isSameAddressValidator(value)
            ),
          addressLine2: yup.string(),
          city: yup
            .string()
            .test('city', t('City', { context: 'required' }), (value: string) =>
              isSameAddressValidator(value)
            ),
          country: yup
            .string()
            .test(
              'country',
              t('Country', { context: 'required' }),
              (value: string) => isSameAddressValidator(value)
            ),
          stateCode: yup.string(),
          stateLabel: yup.string(),
          postalCode: yup
            .string()
            .test(
              'postalCode',
              t('Zip/Postal Code', { context: 'required' }),
              (value: string) => isSameAddressValidator(value)
            )
        })
      }),
    [isSameAddressValidator, t]
  );

  const handleImageUploadModal = useCallback(() => {
    setIsImageUploadModalOpen(
      prevIsImageUploadModalOpen => !prevIsImageUploadModalOpen
    );
  }, []);

  const handleSubmit = useCallback(
    (values: IContactInformationFormValues) => {
      if (selectedUser) {
        selectedUser.isPendingUpdate = true;
        onSubmit({
          ...values,
          officeAddress: values.isSameAddress
            ? values.mailingAddress
            : values.officeAddress
        });
      }
    },
    [selectedUser, onSubmit]
  );

  // redirect to 403 if app is read-only since page only renders a form to edit
  // a non-Ryan user's profile
  useEffect(() => {
    if (
      isAppReadOnly ||
      (selectedUser && !permissionService.canEditClientProfile(selectedUser))
    ) {
      history.replace('/app/403');
    }
  }, [history, isAppReadOnly, permissionService, selectedUser]);

  return (
    <>
      <Card role="region" title="Contact Information">
        {isLoading ? (
          <ContactInformationSkeleton />
        ) : (
          <Formik<IContactInformationFormValues>
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm({ values });
            }}
            validate={values => setIsSameAddress(values.isSameAddress)}
            validationSchema={schema}
          >
            {formik => (
              <ContactInformationForm
                formik={formik}
                onUpdateProfileImage={handleImageUploadModal}
                selectedUser={selectedUser}
              />
            )}
          </Formik>
        )}
      </Card>

      {isImageUploadModalOpen && (
        <UploadProfilePictureModal
          onClose={() => setIsImageUploadModalOpen(false)}
          onUserUpdate={onUserUpdate}
          userGuid={selectedUser ? selectedUser.userGuid : ''}
        />
      )}
    </>
  );
};

export default ContactInformation;
