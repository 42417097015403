import { EntityType } from '../../../interfaces';
import { ApiFailSafeEnums } from '../../../utils/enums/ApiFailSafeEnums';
import {
  IMapAssignmentToUpdateTaskApi,
  IMapGetWorklistAssignmentsResponseToWorklist,
  IMapResultItemToCommon,
  IMapResultItemToDataRequest,
  IMapResultItemToTask,
  TWorklistTaskItem
} from './WorklistMine.interfaces';

export enum StateActionEnums {
  TO_ASSIGN_DATA_UPLOAD = 'toAssignDataUpload',
  TO_COMPLETE = 'toComplete',
  TO_DELETE = 'toDelete',
  TO_EDIT = 'toEdit',
  TO_REASSIGN = 'toRassign',
  TO_REOPEN = 'toReopen'
}

export const mapAssignmentToUpdateTaskApi: IMapAssignmentToUpdateTaskApi = ({
  assignedTo,
  description,
  dueDate,
  engagementGuid,
  isFollowing,
  queueItemGuid,
  statusId,
  title,
  ...rest
}) => {
  const { engagementMilestoneGuid } = rest as TWorklistTaskItem;

  return {
    assignedToUserGuid: assignedTo.userGuid,
    description,
    dueDate: new Date(dueDate),
    engagementGuid,
    engagementMilestoneGuid,
    isCurrentUserWatching: isFollowing,
    queueItemGuid,
    statusId,
    title
  };
};

export const mapGetWorklistAssignmentsResponseToWorklist: IMapGetWorklistAssignmentsResponseToWorklist =
  results => {
    const mappedResults: any = [];

    results.forEach(item => {
      const { entityTypeId } = item;

      let mappedItem;
      switch (entityTypeId) {
        case EntityType.DataRequest:
          mappedItem = mapResultItemToDataRequest(item);
          break;
        case EntityType.Task:
          mappedItem = mapResultItemToTask(item);
          break;
        default:
          return;
      }

      if (!mappedItem) {
        return;
      }

      mappedResults.push(mappedItem);
    });

    return mappedResults;
  };

const mapResultItemToCommon: IMapResultItemToCommon = item => ({
  accountName: item.accountName || ApiFailSafeEnums.NAME,
  assignedTo: {
    name: item.assignedToName || ApiFailSafeEnums.NAME,
    userGuid: item.assignedToUserGuid || ApiFailSafeEnums.GUID,
    userType: item.assignedToUserType || ApiFailSafeEnums.USER_TYPE
  },
  attachments: item.attachments || JSON.parse(ApiFailSafeEnums.LIST),
  commentCount: item.commentsCount || ApiFailSafeEnums.NUM,
  createdBy: {
    name: item.createdByName || ApiFailSafeEnums.NAME,
    userGuid: item.createdBy || ApiFailSafeEnums.GUID,
    userType: item.createdByUserType || ApiFailSafeEnums.USER_TYPE
  },
  createdDate: item.createDate || ApiFailSafeEnums.DATE,
  description: item.description || ApiFailSafeEnums.NAME,
  dueDate: item.dueDate || ApiFailSafeEnums.DATE,
  engagementDisplayNameShort:
    item.engagementDisplayNameShort || ApiFailSafeEnums.NAME,
  engagementGuid: item.engagementGuid || ApiFailSafeEnums.GUID,
  engagementId: item.engagementId || ApiFailSafeEnums.ENGAGEMENT_ID,
  entityTypeId: item.entityTypeId,
  followerCount: item.watcherCount || ApiFailSafeEnums.NUM,
  isEngagementReadOnly: Boolean(item.isEngagementReadOnly),
  isFollowing: Boolean(item.isCurrentUserWatching),
  queueItemGuid: item.queueItemGuid || ApiFailSafeEnums.GUID,
  statusId: item.statusId || ApiFailSafeEnums.STATUS_ID,
  title: item.title || ApiFailSafeEnums.NAME
});

const mapResultItemToDataRequest: IMapResultItemToDataRequest = item => ({
  ...mapResultItemToCommon(item),
  codeAssistance: ApiFailSafeEnums.NAME as string, // TODO: Worklist: What should this be?
  dataSpecs: item.dataSpecs || ApiFailSafeEnums.NAME,
  dateRanges: item.dateRange || JSON.parse(ApiFailSafeEnums.LIST),
  defaultFolderGuid: item.defaultFolderGuid || ApiFailSafeEnums.GUID,
  documentTypes: item.documentTypes || JSON.parse(ApiFailSafeEnums.LIST),
  engagementDisplayNameLong:
    item.engagementDisplayNameLong || ApiFailSafeEnums.NAME,
  erpSystem: {
    erpGuid: item.erpSystem?.erpGuid || ApiFailSafeEnums.GUID,
    name: item.erpSystem?.name || ApiFailSafeEnums.NAME
  },
  isMarkedAsDataDelivered: Boolean(item.markAsDataDelivered),
  jurisdictions: item.jurisdictions || JSON.parse(ApiFailSafeEnums.LIST),
  otherDocumentType: item.otherDocumentType || ApiFailSafeEnums.NAME,
  otherErpSystem: item.otherErpSystem || ApiFailSafeEnums.NAME,
  otherTaxEngine: item.otherTaxEngine || ApiFailSafeEnums.NAME,
  taxEngine: {
    name: item.taxEngine?.name || ApiFailSafeEnums.NAME,
    taxEngineGuid: item.taxEngine?.taxEngineGuid || ApiFailSafeEnums.GUID
  },
  transferredFileCount: item.transferredFilesCount || ApiFailSafeEnums.NUM,
  subStatusId: item.subStatusId || ApiFailSafeEnums.STATUS_ID,
  subTypeName: item.dataRequestTypeName || ApiFailSafeEnums.NAME,
  typeGuid: item.dataRequestTypeGuid || ApiFailSafeEnums.GUID
});

const mapResultItemToTask: IMapResultItemToTask = item => ({
  ...mapResultItemToCommon(item),
  engagementMilestoneGuid:
    item.engagementMilestoneGuid || ApiFailSafeEnums.GUID,
  engagementMilestoneTitle:
    item.engagementMilestoneTitle || ApiFailSafeEnums.NAME
});
